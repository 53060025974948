import "reflect-metadata";

import "./themes/default/default.css";
import "./themes/globals.css";

import {
    InstagramApplicationConfigInterface,
    InstagramApplicationConfigToken,
} from "@apps/instagram/InstagramApplicationConfig";
import {
    TikTokApplicationConfigInterface,
    TikTokApplicationConfigToken,
} from "@apps/tiktok/TikTokApplicationConfig";
import CustomerInfoFromJsonProvider from "@lib/CustomerInfoProvider/CustomerInfoFromJsonProvider";
import FetchAndXHRPatcher from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import { GraphQLApiClient } from "@lib/GraphQLApiClient/GraphQLApiClient";
import GrowaveConfigurationFromJsonProvider from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationFromJsonProvider";
import HistoryPatcher from "@lib/HistoryPatcher";
import { SimpleLocalizationInfoProvider } from "@lib/LocalizationInfoProvider/SimpleLocalizationInfoProvider";
import { ProductIdFromAdditionalInfoProvider } from "@lib/ProductIdManager/ProductIdFromAdditionalInfoProvider";
import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductIdManager } from "@lib/ProductIdManager/ProductIdManager";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";
import Timer from "@lib/timer";
import { AnalyticsEventsCollectorModule } from "@modules/analytics_events_collector/AnalyticsEventsCollectorModule";
import { AuthenticationModule } from "@modules/authentication/AuthenticationModule";
import { CommonModule } from "@modules/common_module/CommonModule";
import { ProductsModule } from "@modules/products/ProductsModule";
import { SdkModule } from "@modules/sdk/SdkModule";
import {
    DependencyContainer,
    container,
    instanceCachingFactory,
} from "tsyringe";

import { CURRENT_APP_INSTAGRAM } from "./constants/current_app";
import { GP_GW_REFFERER } from "./constants/get_params";
import { MinilogLogger } from "./lib/Logger";
import {
    customerInfoProviderToken,
    fetchAndXhrPatcherToken,
    globalLoggerToken,
    globalProductIdManagerToken,
    growaveConfigurationProviderToken,
    growaveConfigurationToken,
    gwStorefrontAppInfoToken,
    isEmbeddedModeToken,
    localStorageToken,
    localizationInfoProviderToken,
    modalManagerToken,
} from "./tokens";
import checkIsEmbeddedMode from "./utils/checkIsEmbeddedMode";
import { ModalManager } from "@components/Modal/ModalManager";

function __gwMain() {
    container.register(globalLoggerToken, {
        useFactory: instanceCachingFactory(() => {
            return new MinilogLogger("global");
        }),
    });
    container
        .resolve(globalLoggerToken)
        .info("Growave main script is initialized!");

    container.register(gwStorefrontAppInfoToken, {
        useFactory: instanceCachingFactory(() => {
            return {
                appName: __APP_NAME__,
                version: __VERSION__,
                currentApp: CURRENT_APP_INSTAGRAM,
                tokenStorageKey: container
                    .resolve(CommonModule)
                    .exports.designModeProvider.isDesignMode()
                    ? "GW_TOKEN__DESIGN_MODE"
                    : "GW_TOKEN",
                appProxyPrefix: "/apps/ssw-instagram",
                appBaseUrl:
                    __APP_ENV__ === "development"
                        ? "https://app.dev.growave.io"
                        : "https://app.growave.io",
                translationsServiceBaseUrl:
                    __APP_ENV__ === "development"
                        ? "https://translations.dev.growave.io"
                        : "https://translations.growave.io",
            };
        }),
    });

    // localStorage
    container.register(localStorageToken, { useValue: localStorage });

    // (utils) historyPatcher
    const historyPatcher = new HistoryPatcher(
        [GP_GW_REFFERER],
        window.history,
        container.resolve(CommonModule).exports.locationController
    );
    historyPatcher.patch();

    // (utils) fetchAndXHRPatcher
    container.register(fetchAndXhrPatcherToken, {
        useFactory: instanceCachingFactory(() => {
            const fetchAndXHRPatcher = new FetchAndXHRPatcher(
                container.resolve(globalLoggerToken)
            );
            fetchAndXHRPatcher.patch();
            return fetchAndXHRPatcher;
        }),
    });
    container.resolve(fetchAndXhrPatcherToken);

    // Is embedded mode
    const isEmbeddedMode = checkIsEmbeddedMode();
    container.registerInstance(isEmbeddedModeToken, isEmbeddedMode);

    // ProductIdManager
    container.register(ProductIdFromSdkProvider, {
        useFactory: instanceCachingFactory(() => {
            return new ProductIdFromSdkProvider();
        }),
    });
    container.register(globalProductIdManagerToken, {
        useFactory: instanceCachingFactory(() => {
            const productIdManager = new ProductIdManager();
            productIdManager.registerProvider(
                new ProductIdFromAdditionalInfoProvider()
            );
            return productIdManager;
        }),
    });

    // ModalManager
    const modalManager = new ModalManager();
    container.registerInstance(modalManagerToken, modalManager);

    // ProductVariantsIdManager
    container.register(ProductVariantIdFromSdkProvider, {
        useFactory: instanceCachingFactory(() => {
            return new ProductVariantIdFromSdkProvider();
        }),
    });

    // (utils) CustomerInfoProvider
    const customerInfoProvider = new CustomerInfoFromJsonProvider(
        container.resolve(globalLoggerToken)
    );
    customerInfoProvider.readData();
    container.registerInstance(customerInfoProviderToken, customerInfoProvider);

    // (utils) growaveConfigurationProvider
    container.register(growaveConfigurationProviderToken, {
        useFactory: instanceCachingFactory(() => {
            return new GrowaveConfigurationFromJsonProvider(
                container.resolve(globalLoggerToken)
            );
        }),
    });

    container.register(growaveConfigurationToken, {
        useFactory: instanceCachingFactory((di: DependencyContainer) => {
            return di
                .resolve(growaveConfigurationProviderToken)
                .getConfiguration();
        }),
    });

    container.register(localizationInfoProviderToken, {
        useFactory: instanceCachingFactory(() => {
            return new SimpleLocalizationInfoProvider(
                container.resolve(growaveConfigurationToken).countryIsoCode,
                container.resolve(growaveConfigurationToken).languageIsoCode,
                container.resolve(growaveConfigurationToken).currencyIsoCode,
                container.resolve(growaveConfigurationToken).gwI18nLocale
            );
        }),
    });

    // (utils) GraphQLApiClient
    container.register(GraphQLApiClient, {
        useFactory: instanceCachingFactory(() => {
            const storefrontApiAccessToken = container.resolve(
                growaveConfigurationToken
            ).storefrontApiAccessToken;
            if (storefrontApiAccessToken) {
                return new GraphQLApiClient(storefrontApiAccessToken);
            }
            return null;
        }),
    });

    container.register(SdkModule, {
        useFactory: instanceCachingFactory(() => new SdkModule()),
    });
    container.resolve(AuthenticationModule);
    container.resolve(ProductsModule);

    container
        .resolve(AnalyticsEventsCollectorModule)
        .exports.eventsCollector.pushEvent(
            container
                .resolve(AnalyticsEventsCollectorModule)
                .exports.eventsFactory.createChunkLoaded("main", -1)
        );

    // Instagram
    container.register<InstagramApplicationConfigInterface>(
        InstagramApplicationConfigToken,
        {
            useValue: {
                placeholderSelector: ".gw-instagram-gallery-placeholder",
            },
        }
    );
    const startLoadingInstagramApplicationTimer = new Timer();
    void import(
        /*webpackChunkName: "InstagramApplication"*/ "@apps/instagram/InstagramApplication"
    ).then(({ InstagramApplication }) => {
        const duration = startLoadingInstagramApplicationTimer.ready();
        container
            .resolve(AnalyticsEventsCollectorModule)
            .exports.eventsCollector.pushEvent(
                container
                    .resolve(AnalyticsEventsCollectorModule)
                    .exports.eventsFactory.createChunkLoaded(
                        "InstagramApplication",
                        duration
                    )
            );
        const instagramApplication = container.resolve(InstagramApplication);
        instagramApplication.init();
    });

    // Tiktok
    container.register<TikTokApplicationConfigInterface>(
        TikTokApplicationConfigToken,
        {
            useValue: {
                placeholderSelector: ".gw-tiktok-gallery-placeholder",
            },
        }
    );
    const startLoadingTikTokApplicationTimer = new Timer();
    void import(
        /*webpackChunkName: "TikTokApplication" */ "@apps/tiktok/TikTokApplication"
    ).then(({ TikTokApplication }) => {
        const duration = startLoadingTikTokApplicationTimer.ready();
        container
            .resolve(AnalyticsEventsCollectorModule)
            .exports.eventsCollector.pushEvent(
                container
                    .resolve(AnalyticsEventsCollectorModule)
                    .exports.eventsFactory.createChunkLoaded(
                        "TikTokApplication",
                        duration
                    )
            );

        const tiktokApplication = container.resolve(TikTokApplication);
        tiktokApplication.init();
    });
}

void __gwMain();
